import moment from "moment";
export function getRequestParams(methodType, request) {
  if (methodType === "GET") {
    const requestParams = {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        CultureCode: sessionStorage.getItem("session_culturecode"),
        Authorization: "Bearer " + sessionStorage.getItem("session_token"),
      },
    };
    return requestParams;
  } else if (methodType === "DELETE") {
    const requestParams = {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        CultureCode: sessionStorage.getItem("session_culturecode"),
        Authorization: "Bearer " + sessionStorage.getItem("session_token"),
      },
    };
    return requestParams;
  } else if (methodType === "PUT" && request !== null) {
    const requestParams = {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        CultureCode: sessionStorage.getItem("session_culturecode"),
        Authorization: "Bearer " + sessionStorage.getItem("session_token"),
      },
      body: JSON.stringify(request),
    };
    return requestParams;
  } else if (methodType === "PUT" && request === null) {
    const requestParams = {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        CultureCode: sessionStorage.getItem("session_culturecode"),
        Authorization: "Bearer " + sessionStorage.getItem("session_token"),
      },
    };
    return requestParams;
  } else {
    const requestParams = {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        CultureCode: sessionStorage.getItem("session_culturecode"),
        Authorization: "Bearer " + sessionStorage.getItem("session_token"),
      },
      body: JSON.stringify(request),
    };
    return requestParams;
  }
}

export function formatDate(date, dateformat) {
  if (dateformat === "MM/dd/yyyy") {
    return moment(date).format("MM/DD/YYYY");
  }
  if (dateformat === "dd/MM/yyyy") {
    return moment(date).format("DD/MM/YYYY");
  }
  if (dateformat === "MMM/dd/yyyy") {
    return (
      moment(date).format("MMM") +
      "-" +
      moment(date).format("D") +
      "-" +
      moment(date).format("YYYY")
    );
  }
  if (dateformat === "dd/MMM/yyyy") {
    return (
      moment(date).format("D") +
      "-" +
      moment(date).format("MMM") +
      "-" +
      moment(date).format("YYYY")
    );
  }
  if (dateformat === "yyyy-MM-dd") {
    return moment(date).format("YYYY-MM-DD");
  }
  if (dateformat === "yyyy/MM/dd") {
    return moment(date).format("YYYY/MM/DD");
  }
  if (dateformat === "MM/dd/yyyy hh:mm") {
    return moment(date).format("MM/DD/YYYY HH:mm");
  }
  if (dateformat === "MM/dd/yyyy hh:mm:ss") {
    return moment(date).format("MM/DD/YYYY HH:mm:ss");
  }
  if (dateformat === "dd/MM/yyyy hh:mm") {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }
  if (dateformat === "dd/MM/yyyy hh:mm:ss") {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }
  if (dateformat === "yyyy-MM-dd HH:mm:ss") {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  if (dateformat === "yyyyMMddHHmmss") {
    return moment(date).format("YYYYMMDDHHmmss");
  }
  if (dateformat === "yyyyMMdd" || dateformat === "yyyymmdd") {
    return moment(date).format("YYYYMMDD");
  }
  if (dateformat === "MMM") {
    return moment(date).format("MMM");
  }
  if (dateformat === "D") {
    return moment(date).format("D");
  }
  if (dateformat === "HH:mm:ss") {
    return moment(date).format("HH:mm:ss");
  }
  return moment(date).format("MM/DD/YYYY HH:mm:ss");
}
export function formatDate01(date, dateformat) {
  if (dateformat === "dd/MM/yy hh:mm") {
    return moment(date).format("DD/MM/YY HH:mm");
  }

  return moment(date).format("DD/MM/YY HH:mm:ss");
}
export function formatTime(date, timeformat) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();
  var hour = "" + d.getHours();
  var minutes = "" + d.getMinutes();
  var seconds = "" + d.getSeconds();

  if (timeformat === "hh:mm") {
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (hour <= 9) {
      hour = "0" + hour;
    }

    if (minutes <= 9) {
      minutes = "0" + minutes;
    }

    return [hour, minutes].join(":");
  }

  if (timeformat === "hh:mm:ss") {
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour <= 9) {
      hour = "0" + hour;
    }

    if (minutes <= 9) {
      minutes = "0" + minutes;
    }

    if (seconds <= 9) {
      seconds = "0" + seconds;
    }

    return [hour, minutes, seconds].join(":");
  }

  return [hour, minutes, seconds].join(":");
}

export function timeDiffCalc(futureDate, currentDate, format) {
  let diffInMilliSeconds =
    Math.abs(new Date(futureDate) - new Date(currentDate)) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";

  if (format === "DHM") {
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }
    difference +=
      hours === 0 || hours === 1 ? `${hours} hrs, ` : `${hours} hrs, `;
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} mins` : `${minutes} mins`;
  } else if (format === "HM") {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hrs, ` : `${hours} hrs, `;
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} mins` : `${minutes} mins`;
  } else if (format === "D") {
    difference += days === 1 ? `${days} day, ` : `${days} days, `;
  } else if (format === "H") {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hrs, ` : `${hours} hrs, `;
  } else if (format === "M") {
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} mins` : `${minutes} mins`;
  } else {
    difference +=
      hours === 0 || hours === 1 ? `${hours} hrs, ` : `${hours} hrs, `;
    difference +=
      minutes === 0 || hours === 1 ? `${minutes} mins` : `${minutes} mins`;
  }

  return difference;
}

export function HasValue(str) {
  return !!str && /^\s*$/.test(str) === false;
}

export function getCurrentDateTime() {
  return (
    sessionStorage.getItem("session_locationTime") === null ||
    sessionStorage.getItem("session_locationTime") === ""
  );
}

export function formatFromDate(date, dateformat) {
  if (dateformat === "MM/dd/yyyy hh:mm") {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    //var hour = "03";
    //var minutes = "01";

    var hour = "" + d.getHours();
    var minutes = "" + d.getMinutes();

    if (hour <= 9) {
      hour = "0" + hour;
    }

    if (minutes <= 9) {
      minutes = "0" + minutes;
    }

    return [month, day, year].join("/") + " " + [hour, minutes].join(":");
  }

  return [month, day, year].join("/");
}

export function formatToDate(date, dateformat) {
  if (dateformat === "MM/dd/yyyy hh:mm") {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var hour = "" + d.getHours();
    var minutes = "" + d.getMinutes();

    //var hour = "02";
    //var minutes = "59";

    if (hour <= 9) {
      hour = "0" + hour;
    }

    if (minutes <= 9) {
      minutes = "0" + minutes;
    }

    return [month, day, year].join("/") + " " + [hour, minutes].join(":");
  }

  return [month, day, year].join("/");
}

export function convertUTCToLocal(utcDateTime) {
  try {
    if (utcDateTime !== "") {
      if (!utcDateTime.toLowerCase().includes("utc")) {
        utcDateTime = `${utcDateTime} UTC`;
      }
      utcDateTime = new Date(utcDateTime).toLocaleString();
    }
    return utcDateTime;
  } catch (ex) {
    console.log(ex);
    return "";
  }
}
