import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import Modal from "react-modal"; //npm install --save react-modal;
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { SelectBox } from "devextreme-react/select-box";
import NumberFormat from "react-number-format";
import { LoadPanel } from "devextreme-react/load-panel";
import { RegEx } from "../../helpers/fixcodes.js";
const position = { of: "#historydiv" };

const customStyles = {
  content: {
    width: "50%",
  },
};

export class UserComponent extends Component {
  static displayName = UserComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      userModelIsOpen: false,
      loadPanelVisible: false,
      handleAssignDataToState: false,
      datasource: null,
      singelData: null,
      userTypeList: [],
      id: 0,
      userTypeId: 0,
      userName: "",
      newPassword: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      isActive: true,
      vIsPasswordComplexity: false,
      vMinLength: 0,
      vMinUpperCaseLetter: 0,
      vMinLowerCaseLetter: 0,
      vMinNumber: 0,
      vMinSpecialCharacter: 0,
      errors: {
        userTypeId: 0,
        userName: "",
        newPassword: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNo: "",
      },
    };
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getUsers.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };
  async componentDidMount() {
    await this.getUsers();
    await this.getUserType();
  }
  // handleInputChange = (event) => {
  //     const target = event.target;
  //     const value = target.type === 'checkbox' ? target.checked : target.value;
  //     const name = target.name;
  //     const rx_live = /^[A-Za-z0-9- ()]*$/;
  //     const alphbet = /^[A-Za-z ]*$/;
  //     // switch (name) {
  //     //     case 'userName':
  //     //         if (rx_live.test(event.target.value))
  //     //             this.setState({ userName: event.target.value });
  //     //         break;
  //     //     case 'firstName':
  //     //         if (alphbet.test(event.target.value))
  //     //             this.setState({ firstName: event.target.value });
  //     //         break;
  //     //     case 'lastName':
  //     //         if (alphbet.test(event.target.value))
  //     //             this.setState({ lastName: event.target.value });
  //     //         break;

  //     //     default:
  //     //         this.setState({ [name]: value });
  //     //         break;
  //     // }

  //     //this.state.errors[name] = "";
  // }
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  getUsers = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let UserId = 0; //parseInt(sessionStorage.getItem('session_userId'));
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = "";
      const requestParams = getRequestParams("GET", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Users/" +
          UserId +
          "/" +
          airlineCode,
        requestParams
      );
      const data = await response.json();

      this.setState({
        datasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getUsers function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getUserType = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Users/GetUserType",
        requestParams
      );
      const data = await response.json();
      this.setState({
        userTypeList: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getUsers function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleUserTypeChange = (selectedOption) => {
    this.setState({
      userTypeId:
        selectedOption.selectedItem !== null
          ? selectedOption.selectedItem.id
          : 0,
    });
  };
  handleAddUser = (e) => {
    this.setState({
      userModelIsOpen: true,
      singelData: null,
    });
  };
  editUserControl = (e, data) => {
    e.preventDefault();
    if (data != null) {
      this.setState({
        userModelIsOpen: true,
        singelData: data,
        id: data.userId,
      });
    }
  };
  isFormValid = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.userName = "";
      errors.userTypeId = "";
      errors.firstName = "";
      errors.lastName = "";
      errors.newPassword = "";
      errors.confirmPassword = "";
      errors.email = "";
      errors.mobileNo = "";

      if (this.state.userName.trim() === "") {
        formIsValid = false;
        errors.userName = "Please enter User Name.";
      }
      if (this.state.userTypeId === 0) {
        formIsValid = false;
        errors.userTypeId = "Please Select User Type.";
      }
      if (this.state.firstName.trim() === "") {
        formIsValid = false;
        errors.firstName = "Please enter First Name.";
      }
      if (this.state.lastName.trim() === "") {
        formIsValid = false;
        errors.lastName = "Please enter Last Name.";
      }
      if (Number(this.state.id) === 0) {
        if (this.state.newPassword === "") {
          formIsValid = false;
          errors.newPassword = "Please enter password.";
        }
      }
      if (Number(this.state.id) === 0) {
        if (this.state.confirmPassword === "") {
          formIsValid = false;
          errors.confirmPassword = "Please enter re-Type password.";
        }
      }
      if (Number(this.state.id) === 0) {
        if (this.state.confirmPassword !== this.state.newPassword) {
          formIsValid = false;
          errors.confirmPassword = "Password does not match.";
        }
      }

      if (this.state.mobileNo === null || this.state.mobileNo.trim() === "") {
        formIsValid = false;
        errors.mobileNo = "Please enter mobile.";
      } else if (
        this.state.mobileNo
          .trim()
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "").length !== 10
      ) {
        formIsValid = false;
        errors.mobileNo = "Invalid mobile number.";
      }
      if (this.state.email === null || this.state.email.trim() === "") {
        formIsValid = false;
        errors.email = "Please enter email.";
      } else if (this.state.email !== "") {
        if (!RegEx.Email.test(this.state.email)) {
          formIsValid = false;
          errors.email = "Please enter valid email.";
        }
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".isFormValid function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };
  handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.isFormValid()) {
        this.setState({ isSubmited: true });
        var newObject = {};
        newObject.UserId = this.state.id;
        newObject.UserTypeId = parseInt(this.state.userTypeId);
        newObject.userName = this.state.userName.trim();
        newObject.Password = this.state.newPassword.trim();
        newObject.FirstName = this.state.firstName.trim();
        newObject.LastName = this.state.lastName.trim();
        newObject.UserEmail = this.state.email.trim();
        newObject.PhoneNumber = this.state.mobileNo.trim();
        newObject.AirlineCode = "QP";
        newObject.IcaoCode = "AKJ";
        newObject.IsActive = this.state.isActive;
        const requestParams = getRequestParams("POST", newObject);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "Users/AddUpdateUserMaster",
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getUsers();
              this.closeUserModal();
            }
            this.setState({ isSubmited: false });
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
          this.setState({ isSubmited: false });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };
  closeUserModal = () => {
    this.setState({
      isSubmited: false,
      userModelIsOpen: false,
      loadPanelVisible: false,
      id: 0,
      userTypeId: 0,
      userName: "",
      newPassword: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      vIsPasswordComplexity: false,
      vMinLength: 0,
      vMinUpperCaseLetter: 0,
      vMinLowerCaseLetter: 0,
      vMinNumber: 0,
      vMinSpecialCharacter: 0,
      errors: {
        userTypeId: 0,
        userName: "",
        newPassword: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNo: "",
      },
    });
  };
  handleAssignDataToState = async () => {
    try {
      if (this.state.singelData === null) {
        this.setState({
          id: 0,
          userTypeId: 0,
          userName: "",
          newPassword: "",
          confirmPassword: "",
          firstName: "",
          lastName: "",
          email: "",
          mobileNo: "",
          isActive: true,
        });
      } else {
        const user = this.state.singelData;
        this.setState({
          id: user.userId,
          userTypeId: user.userTypeId,
          userName: user.userName,
          newPassword: user.password,
          confirmPassword: user.password,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.userEmail,
          mobileNo: user.phoneNumber,
          isActive: user.isActive,
        });
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleAssignDataToState function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  toolbarItemRender() {
    return (
      <Fragment>
        <div className="row">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleAddUser}
          >
            <span title="Submit">
              <i className="fas fa-plus"></i> Add User
            </span>
          </button>
        </div>
      </Fragment>
    );
  }
  render() {
    const renderGridCell = (data) => {
      return (
        <div>
          <a
            href="#"
            className="far fa-edit"
            title="Edit"
            onClick={(e) => {
              this.editUserControl(e, data.data);
            }}
          ></a>
          {/* <a href="#" target="new" onClick={(e) => this.openChangePasswordModel(e, data.data)} title="Change Password"><i className="fas fa-key"></i></a>&nbsp;&nbsp; */}
        </div>
      );
    };
    return (
      <Fragment>
        {/* <div className="card">
                    <div className="card-body">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" onClick={this.handleAddUser}>
                                    <span title="Submit"><i className="fas fa-plus"></i> Add User</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.datasource}
              keyExpr="userId"
              selection={{ mode: "single" }}
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Column
                dataField="userId"
                width={35}
                caption=""
                allowFiltering={false}
                allowSorting={false}
                cellRender={renderGridCell}
              />
              <Column
                dataField="userTypeName"
                caption="User Type"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="userName"
                caption="User Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="firstName"
                caption="First Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="lastName"
                caption="Last Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="userEmail"
                caption="Email"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={300}
              />
              <Column
                dataField="phoneNumber"
                caption="Contact No"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={300}
              />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Users"
              />
            </DataGrid>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.userModelIsOpen}
          style={customStyles}
          className={"react-modal"}
          onAfterOpen={this.handleAssignDataToState}
          onRequestClose={this.closeUserModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleUserSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                {this.state.id > 0 ? "Modify User" : "Add User"}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeUserModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <div className="form-group">
                    <label>User Type </label>
                    <SelectBox
                      dataSource={this.state.userTypeList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select User Type"
                      name="id"
                      defaultValue={0}
                      value={this.state.userTypeId}
                      displayExpr="type"
                      valueExpr="id"
                      onSelectionChanged={this.handleUserTypeChange}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                    />
                    {this.state.errors["userTypeId"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["userTypeId"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <div className="form-group">
                    <label>
                      User Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="userName"
                      placeholder="Enter userName"
                      value={this.state.userName}
                      disabled={this.state.id > 0}
                      title="User Name"
                      maxLength="50"
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["userName"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["userName"]}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                  style={{ display: this.state.id > 0 ? "none" : "block" }}
                >
                  <div className="form-group">
                    <label htmlFor="">
                      Password <span className="text-danger">*</span>
                      <i
                        className="fas fa-info-circle text-info"
                        data-tip
                        data-for="passwordTooltip"
                      ></i>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      name="newPassword"
                      autoComplete="new-password"
                      placeholder="Enter password"
                      maxLength="25"
                      title="new Password"
                      value={this.state.newPassword}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["newPassword"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["newPassword"]}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                  style={{ display: this.state.id > 0 ? "none" : "block" }}
                >
                  <div className="form-group">
                    <label htmlFor="">
                      {" "}
                      Confirm Password <span className="text-danger">*</span>
                      <i
                        className="fas fa-info-circle text-info"
                        data-tip
                        data-for="passwordTooltip"
                      ></i>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      name="confirmPassword"
                      autoComplete="confirmPassword"
                      placeholder="Enter password"
                      maxLength="25"
                      title="ConfirmPassword"
                      value={this.state.confirmPassword}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["confirmPassword"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["confirmPassword"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="firstName"
                      placeholder="Enter first name"
                      maxLength="50"
                      title="First Name"
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["firstName"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["firstName"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="lastName"
                      placeholder="Enter last name"
                      maxLength="50"
                      title="Enter last name"
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["lastName"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["lastName"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <div className="form-group">
                    <label>
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <NumberFormat
                      className="form-control form-control-sm"
                      name="mobileNo"
                      placeholder="(000) 000-0000"
                      title="Mobile Number"
                      value={this.state.mobileNo}
                      onChange={this.handleInputChange}
                      format="(###) ###-####"
                    />

                    {this.state.errors["mobileNo"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["mobileNo"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div className="form-group">
                    <label>
                      Email <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="email"
                      placeholder="test@domain.com"
                      maxLength="100"
                      title="Email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["email"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["email"]}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                  style={{
                    display: this.state.id <= 0 ? "none" : "block",
                    marginTop: "19px",
                  }}
                >
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        checked={this.state.isActive}
                        onChange={this.handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isActive"
                        style={{ fontSize: "inherit" }}
                      >
                        {" "}
                        Is Active &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.handleUserSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && (
                  <span title="Submit">
                    <i className="fas fa-check"></i> Submit
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray "
                onClick={this.closeUserModal}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
