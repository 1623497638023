import React, { Component } from "react";
import DataGrid, {
  Column,
  Export,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { DateFormat } from "../../helpers/fixcodes.js";
import moment from "moment";
import ImageViewerComponent from "../Shared/ImageViewerComponent.jsx";
import { SelectBox } from "devextreme-react";
export class FeedbackComponent extends Component {
  static displayName = FeedbackComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: this.props?.location?.state?.selectedTabId ?? 0,
      dropdownCrewDataSource: [],
      hotelFeedbackGridDataSource: [],
      transportFeedbackGridDataSource: [],
      employeeNumber: this.props?.location?.state?.employeeNumber ?? "",
      searchFromDate:
        this.props?.location?.state?.searchFromDate ??
        moment(new Date()).add(-30, "days"),
      searchToDate: this.props?.location?.state?.searchToDate ?? new Date(),
      isShowImagePopup: false,
      imagePopup: {
        imageViewerTitle: "",
        imageFileUrls: [],
      },
    };
    this.refHotelGrid = React.createRef();
    this.refTransportGrid = React.createRef();
    this.isComponentDidMounted = false;
  }
  async componentDidMount() {
    if (this.isComponentDidMounted) {
      return;
    }
    document.querySelector("#lblPageTitle").innerHTML = "Feedback";
    this.FetchFeedbackData();
    this.isComponentDidMounted = true;
  }
  FetchFeedbackData = () => {
    try {
      setTimeout(async () => {
        await this.GetHotelFeedback();
      }, 0);
      setTimeout(async () => {
        await this.GetTransportFeedback();
      }, 0);
      setTimeout(async () => {
        await this.getAirlineCrewDropdownData();
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };
  getAirlineCrewDropdownData = async () => {
    try {
      let requestParams = getRequestParams("POST", {
        AirlineCode: sessionStorage.getItem("session_airlineCode"),
        Status: "All",
        AirportCode: "",
        FlightNo: "",
      });
      const crewDropdownData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      ).then((resposne) => {
        return resposne.json();
      });
      let airlineCrew = [
        ...new Set(
          crewDropdownData?.payload?.map((item) => {
            return {
              employeeNumber: item.employeeNumber,
              employeeName: `${item.employeeNumber} - ${item.firstName} ${item.lastName}`,
            };
          })
        ),
      ];
      this.setState({
        dropdownCrewDataSource: airlineCrew,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getDropdownData function",
        error
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  GetHotelFeedback = async () => {
    try {
      this.refHotelGrid.current.instance.beginCustomLoading();
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.FromDate = formatDate(this.state.searchFromDate, "yyyy-MM-dd");
      request.ToDate = formatDate(this.state.searchToDate, "yyyy-MM-dd");
      request.EmployeeNumber = this.state.employeeNumber;
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Feedback/get-hotel",
        requestParams
      );
      const data = await response.json();
      this.setState((prev) => ({
        ...prev,
        hotelFeedbackGridDataSource: data?.payload ?? [],
      }));
      this.refHotelGrid.current.instance.repaint();
      this.refHotelGrid.current.instance.refresh();
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getHotelList function",
        err
      );
    } finally {
      this.refHotelGrid.current.instance.endCustomLoading();
    }
  };
  GetTransportFeedback = async () => {
    try {
      this.refTransportGrid.current.instance.beginCustomLoading();
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.FromDate = formatDate(this.state.searchFromDate, "yyyy-MM-dd");
      request.ToDate = formatDate(this.state.searchToDate, "yyyy-MM-dd");
      request.EmployeeNumber = this.state.employeeNumber;
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Feedback/get-transport",
        requestParams
      );
      const data = await response.json();

      this.setState((prev) => ({
        ...prev,
        transportFeedbackGridDataSource: data?.payload ?? [],
      }));
      this.refTransportGrid.current.instance.repaint();
      this.refTransportGrid.current.instance.refresh();
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".GetTransportFeedback function",
        err
      );
    } finally {
      this.refTransportGrid.current.instance.endCustomLoading();
    }
  };
  handleSearchFeedback = (e) => {
    e.preventDefault();
    try {
      this.FetchFeedbackData();
    } catch (error) {
      console.log(error);
    }
  };
  handleOnCloseImagePopup = () => {
    this.setState((prev) => ({
      ...prev,
      isShowImagePopup: false,
      imagePopup: {
        imageViewerTitle: "",
        imageFileUrls: [],
      },
    }));
  };
  handleOnRedirectToComment = (e, data) => {
    e.preventDefault();
    try {
      this.props.history.push({
        pathname: "/feedback/comment",
        state: {
          feedbackId: data.id,
          searchFromDate: this.state.searchFromDate,
          searchToDate: this.state.searchToDate,
          selectedTabId: this.state.selectedTabId,
          employeeNumber: this.state.employeeNumber,
          sourceUrl: "/feedback",
        },
      });
    } catch (ex) {
      console.log(ex);
    }
  };
  handleOnImagePopupClose = () => {
    this.setState((prev) => ({
      ...prev,
      isShowImagePopup: false,
      imagePopup: {
        imageViewerTitle: "",
        imageFileUrls: [],
      },
    }));
  };
  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filtersearchFromDatetime"
                    width="100%"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    useMaskBehavior={true}
                    defaultValue={this.state.searchFromDate}
                    value={this.state.searchFromDate}
                    onValueChanged={(date) => {
                      this.setState((prev) => ({
                        ...prev,
                        searchFromDate: date.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>To Date</label>
                  &nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prev) => ({
                          ...prev,
                          searchFromDate: moment().startOf("day").toDate(),
                          searchToDate: moment().endOf("day").toDate(),
                        }));
                      }}
                    >
                      <b>Today</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prev) => ({
                          ...prev,
                          searchFromDate: moment()
                            .subtract(1, "day")
                            .startOf("day")
                            .toDate(),
                          searchToDate: moment()
                            .subtract(1, "day")
                            .endOf("day")
                            .toDate(),
                        }));
                      }}
                    >
                      <b>Yesterday</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prev) => ({
                          ...prev,
                          searchFromDate: moment()
                            .subtract(6, "day")
                            .startOf("day")
                            .toDate(),
                          searchToDate: moment().endOf("day").toDate(),
                        }));
                      }}
                    >
                      <b>Last 7 Days</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prev) => ({
                          ...prev,
                          searchFromDate: moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          searchToDate: moment()
                            .subtract(1, "month")
                            .endOf("month")
                            .toDate(),
                        }));
                      }}
                    >
                      <b>Last Month</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prev) => ({
                          ...prev,
                          searchFromDate: moment().startOf("month").toDate(),
                          searchToDate: moment().toDate(),
                        }));
                      }}
                    >
                      <b>Current Month</b>
                    </a>
                  </span>
                  <div>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="filtersearchFromDatetime"
                      width="100%"
                      placeholder="dd/MM/yyyy"
                      displayFormat="dd/MM/yyyy"
                      useMaskBehavior={true}
                      defaultValue={this.state.searchToDate}
                      value={this.state.searchToDate}
                      onValueChanged={(date) => {
                        this.setState((prev) => ({
                          ...prev,
                          searchToDate: date.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>Employee Number</label>
                  <SelectBox
                    dataSource={this.state.dropdownCrewDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="employeeNumber"
                    value={this.state.employeeNumber}
                    displayExpr="employeeName"
                    valueExpr="employeeNumber"
                    showClearButton={true}
                    searchEnabled={true}
                    onValueChanged={(e) => {
                      this.setState((prev) => ({
                        ...prev,
                        employeeNumber: e?.value ?? "",
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <br></br>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleSearchFeedback}
                >
                  <span title="Submit">
                    <i className="fas fa-search"></i> Search
                  </span>
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-custom-gray"
                  onClick={() => {
                    window.history.replaceState({}, "");
                    this.setState((prev) => ({
                      ...prev,
                      hotelFeedbackGridDataSource: [],
                      transportFeedbackGridDataSource: [],
                      searchFromDate: moment(new Date()).add(-30, "days"),
                      searchToDate: new Date(),
                      employeeNumber: "",
                      isShowImagePopup: false,
                      imagePopup: {
                        imageViewerTitle: "",
                        imageFileUrls: [],
                      },
                    }));
                  }}
                >
                  <span title="Clear">
                    <i className="fas fa-eraser"></i> Clear
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-2"
                id="custom-tabs-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.selectedTabId === 0 ? "active" : ""
                    }`}
                    id="tabHotel"
                    data-toggle="pill"
                    href="#tabfeedbackhotel"
                    role="tab"
                    aria-selected={
                      this.state.selectedTabId === 0 ? "true" : "false"
                    } //"true"
                    onClick={async () => {
                      this.setState((prev) => ({ ...prev, selectedTabId: 0 }));
                      await this.GetHotelFeedback();
                    }}
                  >
                    Hotel
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.selectedTabId === 1 ? "active" : ""
                    }`}
                    id="tabTransport"
                    data-toggle="pill"
                    href="#tabfeedbacktransport"
                    role="tab"
                    aria-selected={
                      this.state.selectedTabId === 1 ? "true" : "false"
                    } //"false"
                    onClick={async () => {
                      this.setState((prev) => ({ ...prev, selectedTabId: 1 }));
                      await this.GetTransportFeedback();
                    }}
                  >
                    Transport{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className={`tab-pane fade ${
                  this.state.selectedTabId === 0 ? "active show" : ""
                }`}
                id="tabfeedbackhotel"
                role="tabpanel"
              >
                <DataGrid
                  ref={this.refHotelGrid}
                  dataSource={this.state.hotelFeedbackGridDataSource}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  showRowLines={true}
                  showColumnLines={true}
                  onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                      location: "after",
                      widget: "dxButton",
                      options: {
                        icon: "refresh",
                        onClick: async () => {
                          await this.GetHotelFeedback();
                        },
                      },
                    });
                  }}
                  onCellPrepared={(e) => {
                    // if (e.rowType === "header") {
                    //   e.cellElement.style.backgroundColor = "#f0f2f5";
                    //   //e.cellElement.style.color = "white";
                    // }
                    if (e.rowType === "data") {
                      // if (e.column.dataField === "srNo") {
                      //   e.cellElement.style.backgroundColor = "#f0f2f5";
                      // }
                      if (e.column.dataField !== "id") {
                        if (e.data.issueStatusId === 4) {
                          e.cellElement.style.backgroundColor = "#9ACD32";
                        } else if (e.data.issueStatusId === 5) {
                          e.cellElement.style.backgroundColor = "#f57f85";
                        } else if (e.data.issueStatusId === 3) {
                          e.cellElement.style.backgroundColor = "#dff092";
                        } else if (e.data.issueStatusId === 2) {
                          e.cellElement.style.backgroundColor = "#f1df61";
                        }
                      }
                    }
                  }}
                >
                  <Column
                    dataField="id"
                    caption=""
                    allowSorting={false}
                    allowSearch={false}
                    allowExporting={false}
                    allowFiltering={false}
                    width={50}
                    cellRender={(data) => {
                      let isImage = data.data?.attachments?.length > 0;
                      let imageIconCss = `fas fa-image${
                        data.data?.attachments?.length > 1 ? "s" : ""
                      } ${isImage ? "" : "text-dark"}`;
                      let imageLink = (
                        <a
                          href="#"
                          title={`Attachment(s)`}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState((prev) => ({
                              ...prev,
                              isShowImagePopup: true,
                              imagePopup: {
                                imageViewerTitle: data.data?.hotelName,
                                imageFileUrls: data.data?.attachments,
                              },
                            }));
                          }}
                        >
                          <i
                            style={{ cursor: "pointer", marginLeft: "0px" }}
                            className={imageIconCss}
                          />
                        </a>
                      );
                      // let replyLink = !isClosed && allowReply && (
                      let replyLink = (
                        <a href="#" title="Comment(s)">
                          <i
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleOnRedirectToComment(e, data.data);
                            }}
                            style={{
                              cursor: "pointer",
                              marginLeft: `${imageLink ? "8px" : "0px"}`,
                            }}
                            className="fas fa-comment-dots"
                          />
                        </a>
                      );
                      return (
                        <div style={{ textAlign: "center" }}>
                          {imageLink}
                          {replyLink}
                        </div>
                      );
                    }}
                    fixed={true}
                  />
                  <Column
                    dataField="airlineName"
                    caption="Airline"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="airportCode"
                    caption="Airport"
                    allowFiltering={false}
                    allowSearch={true}
                    width={75}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="Employee #"
                    allowFiltering={false}
                    allowSearch={true}
                    width={105}
                  />
                  <Column
                    dataField="crewFullName"
                    caption="Employee Name"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="airlineCrewType"
                    caption="Crew Position"
                    allowFiltering={false}
                    allowSearch={true}
                    width={110}
                  />
                  <Column
                    dataField="crewEmail"
                    caption="Email"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="hotelName"
                    caption="Hotel"
                    allowFiltering={false}
                    allowSearch={true}
                    width={210}
                  />
                  <Column
                    dataField="checkInDate"
                    caption="Check-In Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="checkOutDate"
                    caption="Check-Out Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="roomNumber"
                    caption="Room No"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flight #"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="issueType"
                    caption="Issue Type"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="priority"
                    caption="Issue Priority"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="status"
                    caption="Status"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="driverFeedback"
                    caption="Driver Feedback"
                    allowFiltering={false}
                    allowSearch={true}
                    width={250}
                  />
                  <Column
                    dataField="createdDateTime"
                    caption="Submission Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="resolvedBy"
                    caption="Resolved By"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="resolvedDate"
                    caption="Resolved Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    displayMode="full"
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="HotelFeedback"
                  />
                </DataGrid>
              </div>
              <div
                className={`tab-pane fade ${
                  this.state.selectedTabId === 1 ? "active show" : ""
                }`}
                id="tabfeedbacktransport"
                role="tabpanel"
              >
                <DataGrid
                  ref={this.refTransportGrid}
                  dataSource={this.state.transportFeedbackGridDataSource}
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  showRowLines={true}
                  showColumnLines={true}
                  onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                      location: "after",
                      widget: "dxButton",
                      options: {
                        icon: "refresh",
                        onClick: async () => {
                          await this.GetTransportFeedback();
                        },
                      },
                    });
                  }}
                  onCellPrepared={(e) => {
                    // if (e.rowType === "header") {
                    //   e.cellElement.style.backgroundColor = "#f0f2f5";
                    //   //e.cellElement.style.color = "white";
                    // }
                    if (e.rowType === "data") {
                      // if (e.column.dataField === "srNo") {
                      //   e.cellElement.style.backgroundColor = "#f0f2f5";
                      // }
                      if (e.column.dataField !== "id") {
                        if (e.data.issueStatusId === 4) {
                          e.cellElement.style.backgroundColor = "#9ACD32";
                        } else if (e.data.issueStatusId === 5) {
                          e.cellElement.style.backgroundColor = "#f57f85";
                        } else if (e.data.issueStatusId === 3) {
                          e.cellElement.style.backgroundColor = "#dff092";
                        } else if (e.data.issueStatusId === 2) {
                          e.cellElement.style.backgroundColor = "#f1df61";
                        }
                      }
                    }
                  }}
                >
                  <Column
                    dataField="id"
                    caption=""
                    allowSorting={false}
                    allowSearch={false}
                    allowExporting={false}
                    allowFiltering={false}
                    width={25}
                    cellRender={(data) => {
                      let replyLink = (
                        <a
                          href="#"
                          title="Reply"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleOnRedirectToComment(e, data.data);
                          }}
                        >
                          <i
                            style={{
                              cursor: "pointer",
                              marginLeft: "0px",
                            }}
                            className="fas fa-comment-dots"
                          />
                        </a>
                      );
                      return (
                        <div style={{ textAlign: "center" }}>{replyLink}</div>
                      );
                    }}
                    fixed={true}
                  />
                  <Column
                    dataField="airlineName"
                    caption="Airline"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="airportCode"
                    caption="Airport"
                    allowFiltering={false}
                    allowSearch={true}
                    width={75}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="Employee #"
                    allowFiltering={false}
                    allowSearch={true}
                    width={105}
                  />
                  <Column
                    dataField="crewFullName"
                    caption="Employee Name"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="airlineCrewType"
                    caption="Crew Position"
                    allowFiltering={false}
                    allowSearch={true}
                    width={110}
                  />
                  <Column
                    dataField="crewEmail"
                    caption="Email"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="crewBaseStation"
                    caption="Current Base Station"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="transporterName"
                    caption="Transporter"
                    allowFiltering={false}
                    allowSearch={true}
                    width={210}
                  />
                  <Column
                    dataField="transporterType"
                    caption="Transporter Type"
                    allowFiltering={false}
                    allowSearch={true}
                    width={120}
                  />
                  <Column
                    dataField="transportDateTime"
                    caption="Transportation Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={140}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="flightType"
                    caption="Flight Details"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flight #"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="flightTime"
                    caption="Flight Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="reportingTime"
                    caption="Reporting Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="vehicleDelayedAtPickupPoint"
                    caption="Vehicle Delayed By"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="arriveAtPickupPoint"
                    caption="Time You Arrived At Pickup Point"
                    allowFiltering={false}
                    allowSearch={true}
                    width={220}
                  />
                  <Column
                    dataField="vehiclePostArrivePickupPoint"
                    caption="Cab Post Arrived At Pickup Point"
                    allowFiltering={false}
                    allowSearch={true}
                    width={220}
                  />
                  <Column
                    dataField="driverFeedback"
                    caption="Driver Feedback"
                    allowFiltering={false}
                    allowSearch={true}
                    width={250}
                  />
                  <Column
                    dataField="supervisorFeedback"
                    caption="Supervisor Feedback"
                    allowFiltering={false}
                    allowSearch={true}
                    width={250}
                  />
                  <Column
                    dataField="status"
                    caption="Status"
                    allowFiltering={false}
                    allowSearch={true}
                    width={100}
                  />
                  <Column
                    dataField="createdDateTime"
                    caption="Submission Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="resolvedBy"
                    caption="Resolved By"
                    allowFiltering={false}
                    allowSearch={true}
                    width={150}
                  />
                  <Column
                    dataField="resolvedDate"
                    caption="Resolved Time"
                    allowFiltering={false}
                    allowSearch={true}
                    dataType={"datetime"}
                    width={120}
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Paging defaultPageSize={10} />
                  <Pager
                    displayMode="full"
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="TransportFeedback"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        {this.state.isShowImagePopup && (
          <ImageViewerComponent
            onClose={this.handleOnImagePopupClose}
            imageViewerTitle={this.state.imagePopup.imageViewerTitle}
            imageFileUrl={this.state.imagePopup.imageFileUrls}
          />
        )}
      </>
    );
  }
}
