import React, { Component, useState } from "react";
import { TextArea } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { NavItem, NavLink } from "reactstrap";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import ReactHtmlParser from "html-react-parser"; //npm i html-react-parser;
import ImageViewerComponent from "../Shared/ImageViewerComponent.jsx";
export class FeedbackCommentComponent extends Component {
  static displayName = FeedbackCommentComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRefresh: false,
      feedbackData: null,
    };
    this.feedbackId = !this.props.location.state
      ? 0
      : this.props.location.state.feedbackId;
  }
  componentDidMount() {
    document.querySelector("#lblPageTitle").innerHTML = "Feedback Comment";
    this.fetchApiData();
  }
  fetchApiData() {
    try {
      setTimeout(async () => {
        await this.GetFeedbackComments();
      }, 0);
    } catch (ex) {
      console.log(ex);
    }
  }
  GetFeedbackComments = async () => {
    try {
      this.setState((prev) => ({ ...prev, isLoading: true }));
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Feedback/comment/${
          this.feedbackId
        }/${parseInt(sessionStorage.getItem("session_userId"))}`,
        requestParams
      );
      let feedbackData = null;
      if (response.ok) {
        feedbackData = await response.json();
      }
      this.setState((prev) => ({
        ...prev,
        isLoading: false,
        feedbackData: feedbackData,
      }));
    } catch (err) {
      this.setState((prev) => ({ ...prev, isLoading: false }));
      console.log(
        "Error in " + this.displayName + ".GetFeedbackComments function",
        err
      );
    }
  };

  handleOnRefresh = async () => {
    try {
      this.setState((prev) => ({ ...prev, isRefresh: true }));
      await this.GetFeedbackComments();
      this.setState((prev) => ({ ...prev, isRefresh: false }));
    } catch (ex) {
      console.log(ex);
    }
  };
  render() {
    return (
      <div>
        <form
          method="post"
          style={
            this.state.isLoading
              ? { pointerEvents: "none", opacity: "0.4" }
              : {}
          }
        >
          <div className="content-wrapper">
            <div className="content">
              <div
                className="card mt-2"
                style={{ width: "75%", margin: "0 auto" }}
              >
                <div>
                  <ol className="breadcrumb mb-0">
                    <NavItem>
                      <NavLink
                        className="btn btn-block btn-outline-secondary btn-sm"
                        onClick={this.handleOnRefresh}
                      >
                        <span title="Refresh">
                          <i
                            className={`fas fa-sync ${
                              this.state.isRefresh ? "fa-spin" : ""
                            }`}
                          ></i>{" "}
                          Refresh
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="btn btn-block btn-outline-secondary btn-sm"
                        title="Feedback"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/feedback",
                            state: {
                              searchFromDate:
                                this.props?.location?.state?.searchFromDate ??
                                null,
                              searchToDate:
                                this.props?.location?.state?.searchToDate ??
                                null,
                              selectedTabId:
                                this.props?.location?.state?.selectedTabId ?? 0,
                              employeeNumber:
                                this.props?.location?.state?.employeeNumber ??
                                "",
                            },
                          });
                        }}
                      >
                        <i className="fas fa-comment-dots"></i> Back
                      </NavLink>
                    </NavItem>
                  </ol>
                  <span
                    className="badge badge-pill p-2 mt-2 float-sm-right"
                    style={{
                      backgroundColor:
                        this.state.feedbackData?.isClosed &&
                        this.state.feedbackData?.issueStatusId === 2
                          ? "#28a745"
                          : this.state.feedbackData?.isClosed &&
                            this.state.feedbackData?.issueStatusId === 3
                          ? "#dc3545"
                          : "#ffc107",
                      position: "absolute",
                      right: "20px",
                      top: "0",
                      color:
                        this.state.feedbackData?.isClosed &&
                        this.state.feedbackData?.issueStatusId === 2
                          ? "#FFFFFF"
                          : this.state.feedbackData?.isClosed &&
                            this.state.feedbackData?.issueStatusId === 3
                          ? "#FFFFFF"
                          : "#121212",
                    }}
                  >
                    Status: {this.state.feedbackData?.status}
                  </span>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Employee:{" "}
                          <strong>
                            {this.state.feedbackData?.employeeNumber}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Name:{" "}
                          <strong>
                            {this.state.feedbackData?.crewFullName}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Crew Type:{" "}
                          <strong>
                            {this.state.feedbackData?.airlineCrewType}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Phone:{" "}
                          <strong>
                            {this.state.feedbackData?.crewPhoneNumber}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Email:{" "}
                          <strong>{this.state.feedbackData?.crewEmail}</strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Airline Code:{" "}
                          <strong>
                            {this.state.feedbackData?.airlineName}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Airport:{" "}
                          <strong>
                            {this.state.feedbackData?.airportCode}
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Priority:{" "}
                          <strong>
                            <span
                              className="badge badge-pill badge-warning"
                              style={{ fontSize: "12px" }}
                            >
                              {this.state.feedbackData?.priority}
                            </span>
                          </strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Help Topic:{" "}
                          <strong>{this.state.feedbackData?.issueType}</strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Occurrence Date:{" "}
                          <strong>
                            {this.state.feedbackData?.createdDateTime}
                          </strong>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label>
                          Flight No:{" "}
                          <strong>
                            {this.state.feedbackData?.flightNumber}
                          </strong>
                        </label>
                      </div>
                    </div>
                    {this.state.feedbackData?.typeofFeedback !== 1 && (
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="form-group">
                          <label>
                            Transportation Type:{" "}
                            <strong>
                              {this.state.feedbackData?.transporterType}
                            </strong>
                          </label>
                        </div>
                      </div>
                    )}

                    {this.state.feedbackData?.typeofFeedback === 1 && (
                      <>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Hotel Name:{" "}
                              <strong>
                                {this.state.feedbackData?.hotelName}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Room No:{" "}
                              <strong>
                                {this.state.feedbackData?.roomNumber}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Check-In:{" "}
                              <strong>
                                {this.state.feedbackData?.checkInDate}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Check-Out:{" "}
                              <strong>
                                {this.state.feedbackData?.checkOutDate}
                              </strong>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    {(this.state.feedbackData?.typeofFeedback === 2 ||
                      this.state.feedbackData?.typeofFeedback === 3) && (
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Transporter Name:{" "}
                              <strong>
                                {this.state.feedbackData?.transporterName}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Transporter Date:{" "}
                              <strong>
                                {this.state.feedbackData?.transportDateTime}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Flight Details:{" "}
                              <strong>
                                {this.state.feedbackData?.flightType}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Flight Time:{" "}
                              <strong>
                                {this.state.feedbackData?.flightTime}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Reporting Time:{" "}
                              <strong>
                                {this.state.feedbackData?.reportingTime}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Vehicle delayed by:{" "}
                              <strong>
                                {
                                  this.state.feedbackData
                                    ?.vehicleDelayedAtPickupPoint
                                }
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Time you arrived at pickup point:{" "}
                              <strong>
                                {this.state.feedbackData?.arriveAtPickupPoint}
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Cab post arrived at pickup point:{" "}
                              <strong>
                                {
                                  this.state.feedbackData
                                    ?.vehiclePostArrivePickupPoint
                                }
                              </strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                          <div className="form-group">
                            <label>
                              Current Base Station:{" "}
                              <strong>
                                {this.state.feedbackData?.crewBaseStation}
                              </strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {(this.state.feedbackData?.typeofFeedback === 2 ||
                      this.state.feedbackData?.supervisorFeedback) && (
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label>Supervisor Feedback: </label>
                          <span className="mb-0 textinfo lbl-value">
                            <TextArea
                              style={{
                                padding: "8px",
                                boxSizing: "border-box",
                              }}
                              readOnly={true}
                              value={
                                this.state.feedbackData?.supervisorFeedback ??
                                ""
                              }
                            />
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className="col-lg-3 col-md-3 col-sm-6 col-xs-6"
                      style={{
                        display: this.state.feedbackData?.isClosed || "none",
                      }}
                    >
                      <div className="form-group">
                        <label>
                          Resolved By:{" "}
                          <strong>{this.state.feedbackData?.resolvedBy}</strong>
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
                      style={{
                        display: this.state.feedbackData?.isClosed || "none",
                      }}
                    >
                      <div className="form-group">
                        <label>
                          Resolved Date:{" "}
                          <strong>
                            {this.state.feedbackData?.resolvedDate}
                          </strong>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div
                        style={{
                          border: "1px solid transparent",
                          boxShadow: "0 1px 1px rgb(0 0 0 /5%)",
                          borderRadius: "4px",
                          borderColor: "#dddddd",
                          marginBottom: "15px",
                        }}
                      >
                        {this.state.feedbackData && (
                          <Comment
                            isComment={false}
                            userName={this.state.feedbackData?.crewFullName}
                            commentDate={
                              this.state.feedbackData?.createdDateTime
                            }
                            status={this.state.feedbackData?.status}
                            comment={this.state.feedbackData?.driverFeedback}
                            attachments={this.state.feedbackData?.attachments}
                            isCommentAttachment={false}
                            commentId={0}
                            issueStatusId={
                              this.state.feedbackData?.issueStatusId
                            }
                            isClosed={this.state.feedbackData?.isClosed}
                            imageViewerTitle={
                              this.state.feedbackData?.typeofFeedback === 2
                                ? this.state.feedbackData?.transporterName
                                : this.state.feedbackData?.hotelName
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#panelgrid" }}
          visible={this.state.isLoading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </div>
    );
  }
}
const commentBlock = {
  backgroundColor: "#f1f1f1",
  borderRadius: "0px",
  borderColor: "#d6e9c6",
  padding: "0px",
  color: "#111111",
};
const userCommentBlock = {
  backgroundColor: "#FFFFFF",
  borderRadius: "0px",
  borderColor: "#ffffff",
  padding: "0px",
  color: "#111111",
};
const commentBlackHeaderColor = (issueStatusId) => {
  let bgcolor =
    issueStatusId === 2
      ? "#c1ffcf"
      : issueStatusId === 3
      ? "#ffa9a9"
      : "#fff5d1";
  return {
    backgroundColor: bgcolor,
    borderRadius: "0px",
    borderColor: "#ece6e6",
    padding: "4px",
    color: "#111111",
    borderBottomStyle: "solid",
  };
};
function Comment(props) {
  const [state, setState] = useState({
    isShowImagePopup: false,
    imageFileUrl: "",
  });
  const onClose = () => {
    setState((prev) => ({
      ...prev,
      isShowImagePopup: false,
      imageFileUrl: "",
    }));
  };
  return (
    <>
      <div style={props?.isComment === true ? commentBlock : userCommentBlock}>
        <div style={commentBlackHeaderColor(props?.issueStatusId)}>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
              style={{ fontWeight: "600", color: "#121212", padding: "2px" }}
            >
              &nbsp;&nbsp;{props?.userName}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
              <small> {props?.commentDate}</small> &nbsp;
              <span
                className={`badge badge-pill ${
                  !props?.isComment ? "bg-primary" : ""
                }`}
                style={{
                  backgroundColor:
                    props?.isClosed && props?.issueStatusId === 2
                      ? "#28a745"
                      : props?.isClosed && props?.issueStatusId === 3
                      ? "#dc3545"
                      : "#ffc107",
                  right: "20px",
                  color:
                    props?.isClosed && props?.issueStatusId === 2
                      ? "#FFFFFF"
                      : props?.isClosed && props?.issueStatusId === 3
                      ? "#FFFFFF"
                      : "#FFFFFF",
                }}
              >
                {props?.status}
              </span>
              &nbsp;&nbsp;
            </div>
          </div>
        </div>
        <div
          className="form-group mb-0"
          style={{
            padding: "10px",
            margin: "0px !important",
            fontSize: "14px !important",
          }}
        >
          {ReactHtmlParser(props?.comment ?? "")}
        </div>
        <div
          style={{
            display:
              (props?.attachments && props?.attachments?.length > 0) || "none",
            padding: "10px 15px",
            borderTop: "1px solid #ddd",
            borderBottomLeftRadius: "3px",
            borderBottomRightRadius: "3px",
          }}
        >
          {props?.attachments &&
            props?.attachments?.map((item, i) => (
              <>
                {item?.fileUrl && (
                  <NavLink
                    className="text-xs"
                    style={{
                      marginRight: "6px",
                      color: "#1986d7",
                      fontWeight: "700",
                      cursor: "pointer",
                      padding: "0px 0px 2px 0px",
                    }}
                    key={i}
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        isShowImagePopup: true,
                        imageFileUrl: item?.fileUrl,
                      }));
                    }}
                  >
                    <i
                      className="fas fa-paperclip"
                      style={{ color: "#000000", marginRight: "2px" }}
                    ></i>
                    {item?.fileName}
                  </NavLink>
                )}
              </>
            ))}
        </div>
      </div>
      {state.isShowImagePopup && (
        <ImageViewerComponent
          onClose={onClose}
          imageViewerTitle={props?.imageViewerTitle}
          imageFileUrl={state.imageFileUrl}
        />
      )}
    </>
  );
}
