import { DataGrid, LoadPanel, SelectBox, Template } from "devextreme-react";
import React, { Component } from "react";
import { getRequestParams } from "../../helpers/globalfunctions";
import {
  Column,
  ColumnFixing,
  Export,
  GroupPanel,
  HeaderFilter,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RegEx } from "../../helpers/fixcodes";
const modalStyle = {
  content: {
    width: "50%",
  },
};
const position = { of: "#historydiv" };
export class CrewAddressComponent extends Component {
  static displayName = CrewAddressComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      isSubmitted: false,
      isAddUpdateAddressModalOpen: false,
      crewAddressDataGridSource: [],
      isAirportDropdownLoading: false,
      dropdownAirportDataSource: [],
      isEmployeeDropdownLoading: false,
      dropdownEmployeeDataSource: [],
      isAddressTypeDropdownLoading: false,
      dropdownAddressTypeDataSource: [],
      id: 0,
      filterEmployeeNumber: "",
      employeeNumber: "",
      airlineCode: sessionStorage.getItem("session_airlineCode"),
      airportCode: "",
      addressTypeId: 0,
      addressType: "",
      addressTypeData: "",
      address1: "",
      address2: "",
      landmark: "",
      address: "",
      latitude: "",
      longitude: "",
      errors: {
        employee: "",
        airportCode: "",
        addressType: "",
        latitude: "",
        longitude: "",
        address: "",
      },
    };
    this.refDataGrid = React.createRef();
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarEmployeeDropdownItemRender",
    });
    e.toolbarOptions.items.unshift({
      location: "after",
      template: "toolbarFilterItemRender",
    });
  };
  async componentDidMount() {
    await this.getDataOnPageLoad();
  }
  getDataOnPageLoad = async () => {
    try {
      setTimeout(async () => {
        await this.getCrewAddressList();
      }, 0);
      setTimeout(async () => {
        await this.getEmployeeDropdown();
      }, 0);
      this.setState((prev) => ({ ...prev, loadPanelVisible: true }));
      setTimeout(async () => {
        await this.getAirportDropdownData();
      }, 0);
      setTimeout(async () => {
        await this.getAddressTypeDropdownData();
      }, 0);
      this.setState((prev) => ({ ...prev, loadPanelVisible: false }));
    } catch (error) {
      console.log(error);
      this.setState((prev) => ({ ...prev, loadPanelVisible: false }));
    }
  };
  getCrewAddressList = async () => {
    let crewAddressDataGridSource = [];
    try {
      //this.refDataGrid?.current?.instance?.beginCustomLoading();
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.EmployeeNo = this.state.filterEmployeeNumber;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Crew/get-address`,
        requestParams
      );
      const data = await response.json();
      crewAddressDataGridSource = data ?? [];
      console.log(data, "this data");
    } catch (ex) {
      console.log(ex);
    } finally {
      this.setState((prev) => ({
        ...prev,
        crewAddressDataGridSource: crewAddressDataGridSource,
        loadPanelVisible: false,
      }));
      //this.refDataGrid?.current?.instance?.endCustomLoading();
    }
  };
  getEmployeeDropdown = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let requestParams = getRequestParams("POST", {
        AirlineCode: airlineCode,
        Status: "Active",
        AirportCode: "",
        FlightNo: "",
      });
      this.setState((prev) => ({ ...prev, isEmployeeDropdownLoading: true }));
      const crewDropdownData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      ).then((response) => {
        return response.json();
      });
      this.setState((prev) => ({
        ...prev,
        isEmployeeDropdownLoading: false,
        dropdownEmployeeDataSource: crewDropdownData.payload ?? [],
      }));
    } catch (error) {
      console.log(error);
      this.setState({
        isEmployeeDropdownLoading: false,
      });
    }
  };
  getAirportDropdownData = async () => {
    try {
      this.setState((prev) => ({
        ...prev,
        isAirportDropdownLoading: true,
      }));
      let url = `${
        process.env.REACT_APP_API_BASE_URL
      }Airport/AirportDropdown/${sessionStorage.getItem(
        "session_airlineCode"
      )}`;
      const airportDropdownData = await fetch(url).then((response) =>
        response.json()
      );
      this.setState({
        isAirportDropdownLoading: false,
        dropdownAirportDataSource: airportDropdownData.payload ?? [],
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getAirportDropdownData function",
        error
      );
      this.setState({
        isAirportDropdownLoading: false,
      });
    }
  };
  getAddressTypeDropdownData = async () => {
    try {
      let requestParams = getRequestParams("GET");
      this.setState((prev) => ({
        ...prev,
        isAddressTypeDropdownLoading: true,
      }));
      const addressTypeDropdownData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/address-type-dropdown",
        requestParams
      ).then((response) => response.json());
      this.setState({
        isAddressTypeDropdownLoading: false,
        dropdownAddressTypeDataSource: addressTypeDropdownData,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getAddressTypeDropdownData function",
        error
      );
      this.setState({
        isAddressTypeDropdownLoading: false,
      });
    }
  };

  openAddressModal = (e) => {
    e.preventDefault();
    this.setState({
      isAddUpdateAddressModalOpen: true,
    });
  };
  closeAddressModal = (e) => {
    e.preventDefault();
    this.setState({
      isAddUpdateAddressModalOpen: false,
    });
  };
  isAddressValid = () => {
    let isValid = true;
    let err = this.state.errors;
    err.employee = "";
    err.airportCode = "";
    err.addressType = "";
    err.latitude = "";
    err.longitude = "";
    err.address = "";
    if (!this.state.employeeNumber) {
      isValid = false;
      err.employee = "Please select employee number";
    }
    if (!this.state.airportCode) {
      isValid = false;
      err.airportCode = "Please enter airportCode";
    }
    if (!this.state.addressTypeId) {
      isValid = false;
      err.addressType = "Please enter addressType";
    }
    if (!this.state.latitude) {
      isValid = false;
      err.latitude = "Please enter latitude";
    }
    if (!this.state.longitude) {
      isValid = false;
      err.longitude = "Please enter longitude";
    }
    if (!this.state.address) {
      isValid = false;
      err.address = "Please enter address";
    }
    this.setState((prev) => ({ ...prev, errors: err }));
    return isValid;
  };
  handleOnSaveAddress = async (e) => {
    e.preventDefault();
    try {
      if (this.isAddressValid()) {
        let msg = `Are you sure want to ${
          this.state.id > 0 ? "edit" : "add"
        } address for ${this.state.employeeNumber} `;
        let isConfirm =
          this.state.id > 0
            ? await this.isConfirm("edit", msg)
            : await this.isConfirm("add", msg);

        if (isConfirm) {
          var request = {};
          request.RowIndex = this.state.id;
          request.AirlineCode = this.state.airlineCode;
          request.EmployeeNo = this.state.employeeNumber;
          request.AddressType = this.state.addressType;
          request.AddressTypeId = this.state.addressTypeId;
          request.Address = this.state.address;
          request.PostalCode = this.state.postalCode;
          request.Latitude = this.state.latitude;
          request.Longitude = this.state.longitude;
          request.Landmark = this.state.landmark;
          request.Address1 = this.state.address1;
          request.Address2 = this.state.address2;
          request.AddressTitle = this.state.addressTitle;
          request.BaseStation = this.state.airportCode;
          const requestParams = getRequestParams("POST", request);
          const response = await fetch(
            process.env.REACT_APP_API_BASE_URL + "Crew/save-address",
            requestParams
          );
          const data = await response.json();
          if (data.isSuccess) {
            await Swal.fire({
              icon: data.isSuccess ? "success" : "error",
              title: data.isSuccess ? "Success" : "Oops...",
              text:
                data.isSuccess === false &&
                (data.message === null || data.message === "")
                  ? "Unable to process the request."
                  : data.message === null || data.message === ""
                  ? `Crew address added successfully.`
                  : data.message,
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState((prev) => ({ ...prev, employeeNumber: "" }));
                this.getCrewAddressList();
                this.closeAddressModal(e);
              }
              this.setState({ isSubmitted: false });
            });
          } else {
            toast.error(
              data.message === null || data.message === ""
                ? "Unable to process the request."
                : data.message,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: "310px" },
                hideProgressBar: false,
                newestOnTop: false,
                closeButton: false,
              }
            );
            this.setState({ isSubmitted: false });
          }
        }
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        error
      );
      this.setState({ isSubmitted: false });
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let err = this.state.errors;
    switch (name) {
      case "airlineCode":
        err.airlineCode = "";
        if (value.trim() === "" || value.length <= 2) {
          this.setState((prev) => ({ ...prev, airlineCode: value }));
        }
        break;
      case "houseApartmentNo":
        if (value.trim() === "" || value.length <= 50) {
          this.setState((prev) => ({ ...prev, address1: value }));
        }
        break;
      case "address2":
        if (value.trim() === "" || value.length <= 75) {
          this.setState((prev) => ({ ...prev, address2: value }));
        }
        break;
      case "landmark":
        if (value.trim() === "" || value.length <= 50) {
          this.setState((prev) => ({ ...prev, landmark: value }));
        }
        break;
      case "latitude":
        err.latitude = "";
        if (value.trim() === "" || RegEx.LatLong.test(value)) {
          this.setState((prev) => ({ ...prev, latitude: value }));
        }
        break;
      case "longitude":
        err.longitude = "";
        if (value.trim() === "" || RegEx.LatLong.test(value)) {
          this.setState((prev) => ({ ...prev, longitude: value }));
        }
        break;
      case "address":
        err.address = "";
        if (value.trim() === "" || value.length <= 100) {
          this.setState((prev) => ({ ...prev, address: value }));
        }
        break;

      default:
        break;
    }
  };
  handleOnEmployeeSelectionChange = async (e) => {
    try {
      let err = this.state.errors;
      err.employee = "";
      this.setState({
        employeeNumber:
          e.selectedItem !== null ? e.selectedItem.employeeNumber : "",
        errors: err,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnEmployeeSelectionChange function",
        error
      );
    }
  };
  handleOnAirportSelectionChange = (e) => {
    try {
      let err = this.state.errors;
      err.airportCode = "";
      this.setState({
        airportCode: e.selectedItem !== null ? e.selectedItem.airportCode : "",
        errors: err,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleAirportChange function",
        error
      );
    }
  };
  handleOnFilterCrewAddress = async () => {
    try {
      await this.getCrewAddressList();
    } catch (error) {
      console.log(error);
    }
  };
  handleOnOpenUpdateAddressModal = (e, data) => {
    e.preventDefault();
    try {
      this.setState((prev) => ({
        ...prev,
        isAddUpdateAddressModalOpen: true,
        id: data.rowIndex,
        employeeNumber: data.employeeNo,
        airlineCode: data.airlineCode,
        airportCode: data.baseStation,
        address1: data.address1,
        address2: data.address2,
        landmark: data.landmark,
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.address,
        addressTypeId: data.addressTypeId,
        addressType: data.addressType,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  isConfirm = async (type, msg, title) => {
    let isResult = false;
    let confirmMessage = "";
    if (type === "delete") {
      if (msg !== "") {
        confirmMessage =
          "Are you sure want to delete this address <b>" + msg + "</b>?";
      } else {
        confirmMessage = "Are you sure want to delete this address?";
      }
    } else if (type === "status") {
      confirmMessage = msg;
    } else if (type === "add") {
      confirmMessage = msg;
    } else if (type === "edit") {
      confirmMessage = msg;
    }
    await Swal.fire({
      title: title,
      html: "<small>" + confirmMessage + "</small>",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText:
        type === "delete"
          ? "Delete"
          : type === "add"
          ? "Add"
          : type === "edit"
          ? "edit"
          : title,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isResult = true;
      }
    });
    return isResult;
  };
  handleOnAddressIsDefault = async (e, data, isDefault) => {
    e.preventDefault();
    try {
      const { rowIndex, airlineCode, employeeNo } = { ...data };
      let msg = `Are you sure want to ${
        isDefault
          ? "set this address as default"
          : "remove this address from default"
      } `;
      let title = isDefault ? "Set Default" : "Remove Default";
      let isConfirm = await this.isConfirm("status", msg, title);
      console.log(data);
      if (isConfirm) {
        var request = {};
        request.AirlineCode = airlineCode;
        request.EmployeeNumber = employeeNo;
        request.IsDefault = isDefault;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}Crew/update-address-default/${rowIndex}`,
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Address has been ${
                    !isDefault ? "removed from defaults" : " set to default"
                  } successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.getCrewAddressList();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ". handleOnAddressIsDefault function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleOnDeleteCrewAddress = async (event, data) => {
    event.preventDefault();
    try {
      let rowIndex = data.rowIndex;
      let isConfirm = await this.isConfirm("delete", "", "Delete");
      if (isConfirm) {
        const requestParams = getRequestParams("DELETE");
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}Crew/delete-address/${rowIndex}`,
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Crew address has been set deleted successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.getCrewAddressList();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleOnDeleteCrewAddress function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleOnCellPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.isDefault === true && e.column.dataField !== "rowIndex") {
        e.cellElement.style.backgroundColor = "lightGreen";
      }
    }
  };
  render() {
    const renderGridCell = (data) => {
      let updateLink = (
        <a
          title="Update Address"
          onClick={(e) => {
            this.handleOnOpenUpdateAddressModal(e, data.data);
          }}
        >
          <i className="fas fa-edit"></i>
        </a>
      );
      let notDefaultLink = data.data.isDefault ? (
        <a
          title="Reset Default"
          style={{ paddingLeft: "6px" }}
          onClick={async (e) => {
            await this.handleOnAddressIsDefault(e, data.data, false);
          }}
        >
          <i className="fas fa-toggle-on text-danger"></i>
        </a>
      ) : (
        ""
      );
      let defaultLink = !data.data.isDefault ? (
        <a
          title="Set Default"
          style={{ paddingLeft: "6px" }}
          onClick={async (e) => {
            await this.handleOnAddressIsDefault(e, data.data, true);
          }}
        >
          <i className="fas fa-toggle-off"></i>
        </a>
      ) : (
        ""
      );
      return (
        <span style={{ textAlign: "left", width: "auto" }}>
          {updateLink}
          {notDefaultLink === "" || notDefaultLink}
          {defaultLink === "" || defaultLink}
          <a
            title="Delete"
            style={{ paddingLeft: "6px" }}
            onClick={(e) => {
              this.handleOnDeleteCrewAddress(e, data.data);
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </a>
        </span>
      );
    };
    return (
      <>
        <div className="card">
          <div className="card-body">
            <DataGrid
              ref={this.refDataGrid}
              dataSource={this.state.crewAddressDataGridSource}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              showRowLines={true}
              showColumnLines={true}
              onToolbarPreparing={this.onToolbarPreparing}
              onCellPrepared={this.handleOnCellPrepared}
            >
              <Column
                dataField="rowIndex"
                caption=""
                allowSorting={false}
                allowSearch={false}
                allowExporting={false}
                allowFiltering={false}
                width={"auto"}
                alignment={"left"}
                fixed={true}
                cellRender={renderGridCell}
              />
              <Column
                dataField="employeeNo"
                caption="Employee #"
                allowFiltering={false}
                allowSearch={true}
                fixed={true}
              />
              <Column
                dataField="crewFullName"
                caption="Crew Name"
                allowFiltering={false}
                allowSearch={true}
              />
              <Column
                dataField="airlineCode"
                caption="Airline"
                allowFiltering={false}
                allowSearch={true}
                width={80}
              />
              <Column
                dataField="baseStation"
                caption="Base Station"
                allowFiltering={false}
                allowSearch={true}
                width={115}
              />
              <Column
                dataField="address1"
                caption="House / Apartment No"
                allowFiltering={false}
                allowSearch={true}
                width={170}
              />
              <Column
                dataField="address2"
                caption="Road / Area"
                allowFiltering={false}
                allowSearch={true}
                width={150}
              />
              <Column
                dataField="landmark"
                caption="Landmark"
                allowFiltering={false}
                allowSearch={true}
                width={150}
              />
              <Column
                dataField="address"
                caption="Address"
                allowFiltering={false}
                allowSearch={true}
                width={350}
              />
              <Column
                dataField="addressType"
                caption="Address Type"
                allowFiltering={false}
                allowSearch={true}
                width={120}
              />
              <Column
                dataField="latitude"
                caption="Latitude"
                allowFiltering={false}
                allowSearch={true}
                width={120}
              />
              <Column
                dataField="longitude"
                caption="Longitude"
                allowFiltering={false}
                allowSearch={true}
                width={120}
              />
              <Column
                dataField="isDefault"
                caption="Default Address"
                allowFiltering={false}
                allowSearch={true}
                width={120}
              />
              <Column
                dataField="city"
                caption="City"
                allowFiltering={false}
                allowSearch={true}
                width={100}
              />
              <Column
                dataField="state"
                caption="State"
                allowFiltering={false}
                allowSearch={true}
                width={100}
              />
              <Column
                dataField="createdDate"
                caption="Created Date"
                allowFiltering={false}
                allowSearch={true}
                dataType="datetime"
                format="dd/MM/yyyy HH:mm"
                width={120}
              />
              <Column
                dataField="updatedDate"
                caption="Updated Date"
                allowFiltering={false}
                allowSearch={true}
                dataType="datetime"
                format="dd/MM/yyyy HH:mm"
                width={120}
              />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              <Template
                name="toolbarEmployeeDropdownItemRender"
                render={() => {
                  return (
                    <div className="row">
                      <div className="form-group mt-1">
                        <SelectBox
                          dataSource={this.state.dropdownEmployeeDataSource}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Employee"
                          name="filterEmployeeNumber"
                          value={this.state.filterEmployeeNumber}
                          displayExpr="employeeNumber"
                          valueExpr="employeeNumber"
                          onSelectionChanged={(e) => {
                            this.setState({
                              filterEmployeeNumber:
                                e.selectedItem !== null
                                  ? e.selectedItem.employeeNumber
                                  : "",
                            });
                          }}
                          showClearButton={true}
                          disabled={false}
                          searchEnabled={true}
                        />
                        {this.state.isEmployeeDropdownLoading && (
                          <i
                            className="fas fa-spinner fa-spin"
                            style={{
                              float: "right",
                              marginTop: "-22px",
                              marginRight: "12px",
                              color: "green",
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                  );
                }}
              />
              <Template
                name="toolbarTemplate"
                render={() => {
                  return (
                    <div className="row">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.openAddressModal}
                      >
                        <span title="Submit">
                          <i className="fas fa-plus"></i>Add Crew Address
                        </span>
                      </button>
                    </div>
                  );
                }}
              />
              <Template
                name="toolbarFilterItemRender"
                render={() => {
                  return (
                    <div className="row">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.handleOnFilterCrewAddress}
                      >
                        <span title="Submit">
                          <i className="fas fa-filter"></i>Filter
                        </span>
                      </button>
                    </div>
                  );
                }}
              />
              <HeaderFilter visible={true} />
              <ColumnFixing enabled={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Export enabled={true} fileName="CrewAddress" />
            </DataGrid>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.isAddUpdateAddressModalOpen}
          style={modalStyle}
          className={"react-modal"}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
          onAfterClose={() => {
            this.setState((...prev) => ({
              ...prev,
              id: 0,
              employeeNumber: "",
              airportCode: "",
              addressTypeId: 0,
              addressType: "",
              address1: "",
              address2: "",
              landmark: "",
              address: "",
              latitude: "",
              longitude: "",
              errors: {
                employee: "",
                airportCode: "",
                addressType: "",
                latitude: "",
                longitude: "",
                address: "",
              },
            }));
          }}
        >
          <div className="modal-header">
            <h6 className="modal-title">
              {this.state.id > 0 ? "Edit Address - " : "Add Address"}
              {this.state.employeeNumber}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeAddressModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Employee Number<span className="text-danger">*</span>
                  </label>
                  <SelectBox
                    dataSource={this.state.dropdownEmployeeDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="employeeNumber"
                    value={this.state.employeeNumber}
                    displayExpr="employeeNumber"
                    valueExpr="employeeNumber"
                    readOnly={this.state.id === 0 ? false : true}
                    onSelectionChanged={this.handleOnEmployeeSelectionChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {this.state.errors["employee"]?.length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["employee"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Base Station<span className="text-danger">*</span>
                  </label>
                  <SelectBox
                    dataSource={this.state.dropdownAirportDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportCode"
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleOnAirportSelectionChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {this.state.errors["airportCode"]?.length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["airportCode"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Airline<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="airlineCode"
                    title="airlineCode"
                    placeholder="Airline Code"
                    value={this.state.airlineCode}
                    onChange={this.handleInputChange}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Address Type<span className="text-danger">*</span>
                  </label>
                  <SelectBox
                    dataSource={this.state.dropdownAddressTypeDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Address Type"
                    name="dropdownAddressTypeDataSource"
                    value={this.state.addressTypeId}
                    displayExpr="name"
                    valueExpr="id"
                    onSelectionChanged={(e) => {
                      let err = this.state.errors;
                      err.addressType = "";
                      this.setState((prev) => ({
                        ...prev,
                        addressTypeId:
                          e.selectedItem !== null ? e.selectedItem.id : 0,
                        addressType:
                          e.selectedItem !== null ? e.selectedItem.name : "",
                        errors: err,
                      }));
                    }}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {this.state.errors["addressType"]?.length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["addressType"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>House/Apartment No</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="houseApartmentNo"
                    title="houseApartmentNo"
                    placeholder="House/Apartment No"
                    value={this.state.address1}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>Road/Area</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="address2"
                    title="address2"
                    placeholder="Road/Area"
                    value={this.state.address2}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>Landmark</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="landmark"
                    title="landmark"
                    placeholder="Landmark"
                    value={this.state.landmark}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Latitude<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="latitude"
                    title="latitude"
                    placeholder="Latitude"
                    value={this.state.latitude}
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors["latitude"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["latitude"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>
                    Longitude<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="longitude"
                    title="longitude"
                    placeholder="Longitude"
                    value={this.state.longitude}
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors["longitude"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["longitude"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Address<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    name="address"
                    title="address"
                    placeholder="Address"
                    value={this.state.address}
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors["address"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["address"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-success"
              onClick={async (e) => {
                await this.handleOnSaveAddress(e);
              }}
            >
              {this.state.isSubmitted && (
                <span title="Save">
                  <i className="fas fa-spinner fa-spin"></i> Save
                </span>
              )}
              {!this.state.isSubmitted && (
                <span title="Save">
                  <i className="fas fa-check"></i> Save
                </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-custom-gray "
              onClick={this.closeAddressModal}
              title="Close"
            >
              <i className="fas fa-times"></i> Close
            </button>
          </div>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={
            this.state.loadPanelVisible || this.state.isEmployeeDropdownLoading
          }
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </>
    );
  }
}
