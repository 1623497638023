import { Gallery } from "devextreme-react";
import React from "react";
import Modal from "react-modal"; //npm install --save react-modal;
export default function ImageViewerComponent({
  onClose,
  imageViewerTitle,
  imageFileUrl,
}) {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={{ content: { width: "50%" } }}
      className={"react-modal"}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
    >
      <div className="modal-header">
        <h6 className="modal-title">{imageViewerTitle ?? ""}</h6>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="form-group">
              <Gallery
                id="gallery"
                dataSource={
                  Array.isArray(imageFileUrl) ? imageFileUrl : [imageFileUrl]
                }
                height={450}
                slideshowDelay={5000}
                loop={true}
                showNavButtons={true}
                showIndicator={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-custom-gray "
          onClick={onClose}
          title="Close"
        >
          <i className="fas fa-times"></i> Close
        </button>
      </div>
    </Modal>
  );
}
