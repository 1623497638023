import React, { Component } from "react";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
  MasterDetail,
} from "devextreme-react/data-grid";
import Modal from "react-modal";
import PivotGrid, {
  FieldChooser,
  Scrolling,
} from "devextreme-react/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { LoadPanel } from "devextreme-react/load-panel";
import moment from "moment";
import { ChildBookingDataComponent } from "./ChildBookingDataComponent.jsx";

export class HotelBookingComponent extends Component {
  static displayName = HotelBookingComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      isFiltered: false,
      loadPanelVisible: false,
      dropdownAirportDataSource: [],
      dropdownHotelDataSource: [],
      filterFromDatetime: moment(new Date()).add(-30, "days"),
      filterToDatetime: new Date(),
      dropdownCrewDataSource: [],
      airportCode: "",
      hotelId: 0,
      employeeNumber: "",
      hotelBookingLst: [],
      hotelBookingRateLst: [],
      /* pivot grid tab property*/
      pivotGridHotelSummaryDataSource: [],
      pivotPopupGuestDetailGridDataSource: [],
      popupPivotBookingGuestDetailsTitle: "",
      popupPivotBookingGuestDetailsVisible: false,
      rowHeaderLayout: true,

      /* booking summary details tab property*/
      hotelBookingSummaryGridDataSource: [],
      downloadContent: {
        showModalPopup: false,
        downloadContentTitle: "Request Content",
        responseContent: "",
        requestContent: "",
        cancelContent: "",
        downloadData: "",
        activatedButtonId: 1,
      },
    };
  }
  async componentDidMount() {
    await this.getDropdownData();
  }
  /* #region Filtered Main Grrid Part */
  getDropdownData = async () => {
    try {
      this.setState({
        loadPanelVisible: true,
      });
      let requestParams = getRequestParams("POST", {
        AirlineCode: sessionStorage.getItem("session_airlineCode"),
        Status: "Active",
        AirportCode: "",
        FlightNo: "",
      });
      const crewDropdownData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      ).then((resposne) => {
        return resposne.json();
      });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      requestParams = getRequestParams("GET");
      const hotelDropdownData = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Hotel/GetDropDownAllHotelsWeb/${airlineCode}`,
        requestParams
      ).then((response) => response.json());

      requestParams = getRequestParams("POST", {
        AirlineCode: sessionStorage.getItem("session_airlineCode"),
        UserId: parseInt(sessionStorage.getItem("session_userId")),
        UserName: sessionStorage.getItem("session_userName"),
        SourceId: 0,
      });
      const airportDropdownData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      ).then((response) => response.json());

      this.setState({
        dropdownCrewDataSource: crewDropdownData.payload ?? [],
        dropdownHotelDataSource: hotelDropdownData.payload ?? [],
        dropdownAirportDataSource: airportDropdownData.payload ?? [],
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getDropdownData function",
        error
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleAirportChange = (e) => {
    try {
      this.setState({
        airportCode: e.selectedItem !== null ? e.selectedItem.airportCode : "",
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleAirportChange function",
        error
      );
    }
  };
  handleHotelChange = (e) => {
    try {
      this.setState({
        hotelId: e.selectedItem !== null ? e.selectedItem.hotelId : 0,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleHotelChange function",
        error
      );
    }
  };
  handleOnFilterFromDateChanged = (date) => {
    try {
      this.setState({
        filterFromDatetime: date.value,
        filterToDatetime: moment(new Date(date.value)).add(30, "days"),
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnFilterFromDateChanged function",
        error
      );
    }
  };
  handleOnFilterToDateChanged = (date) => {
    try {
      this.setState({ filterToDatetime: date.value });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnFilterToDateChanged function",
        error
      );
    }
  };
  handleEmployeeChange = async (e) => {
    try {
      this.setState({
        employeeNumber:
          e.selectedItem !== null ? e.selectedItem.employeeNumber : "",
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleEmployeeChange function",
        error
      );
    }
  };
  handleOnFilterBookingData = async (e) => {
    e.preventDefault();
    try {
      this.setState({ loadPanelVisible: true, isFiltered: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.HotelId = this.state.hotelId;
      request.FromTime = formatDate(
        this.state.filterFromDatetime,
        "yyyy-MM-dd 00:00:00"
      );
      request.ToTime = formatDate(
        this.state.filterToDatetime,
        "yyyy-MM-dd 23:59:59"
      );
      request.EmployeeNumber = this.state.employeeNumber;
      request.AirportCode = this.state.airportCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "HotelBooking/data",
        requestParams
      );
      const data = await response.json();
      let hotelBookingLst = [...(data?.payload?.hotelBooking ?? [])];
      let hotelBookingSummaryLst = [
        ...(data?.payload?.hotelBookingSummary ?? []),
      ];
      let hotelBookingRateLst = [...(data?.payload?.hotelBookingRate ?? [])];
      hotelBookingLst.map(
        (item) =>
          (item.startDate = moment(item.startDate, "YYYY-MM-DD").format(
            "DD-MMM-YY"
          ))
      );
      hotelBookingSummaryLst.map(
        (item) =>
          (item.startDate = moment(item.startDate, "YYYY-MM-DD").format(
            "DD-MMM-YY"
          ))
      );
      const pivotGridDataSourceStore = new PivotGridDataSource({
        fields: [
          {
            caption: "Airport Code",
            dataField: "airportCode",
            expanded: true,
            sortBySummaryField: "count",
            sortBySummaryPath: [],
            area: "row",
          },
          {
            caption: "Hotel Name",
            dataField: "hotelName",
            expanded: false,
            sortBySummaryField: "count",
            sortBySummaryPath: [],
            area: "row",
          },
          {
            caption: "Flight Number",
            dataField: "flightNumber",
            expanded: false,
            sortBySummaryField: "count",
            sortBySummaryPath: [],
            area: "row",
          },
          {
            dataField: "startDate",
            area: "column",
            dataType: "date",
            groupName: "Date",
          },
          { groupName: "Date", groupInterval: "year", groupIndex: 0 },
          {
            groupName: "Date",
            groupInterval: "month",
            groupIndex: 1,
            format: "dd-MMM-yy",
            selector: function (data) {
              return new Date(data.startDate);
            },
          },
          {
            caption: "Employee Number",
            dataField: "employeeNumber",
            dataType: "number",
            summaryType: "count",
            area: "data",
          },
        ],
        store: hotelBookingLst.filter(
          (f) => f.isHotelBookingCancelled === false
        ),
      });
      this.setState({
        hotelBookingLst: hotelBookingLst,
        hotelBookingRateLst: hotelBookingRateLst,
        pivotGridHotelSummaryDataSource: pivotGridDataSourceStore,
        hotelBookingSummaryGridDataSource: hotelBookingSummaryLst,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnFilterBookingData function",
        error
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
        isFiltered: false,
      });
    }
  };

  /* #endregion Filtered Main Grrid Part*/
  handleOnClearFilter = () => {
    try {
      this.setState({
        isFiltered: false,
        loadPanelVisible: false,
        filterFromDatetime: moment(new Date()).add(-30, "days"),
        filterToDatetime: new Date(),
        airportCode: "",
        hotelId: 0,
        employeeNumber: "",
        hotelBookingLst: [],
        hotelBookingRateLst: [],
        /* pivot grid tab property*/
        pivotGridHotelSummaryDataSource: [],
        pivotPopupGuestDetailGridDataSource: [],
        popupPivotBookingGuestDetailsTitle: "",
        popupPivotBookingGuestDetailsVisible: false,
        rowHeaderLayout: true,
        /* booking summary details tab property*/
        hotelBookingSummaryGridDataSource: [],
        downloadContent: {
          showModalPopup: false,
          downloadContentTitle: "Request Content",
          responseContent: "",
          requestContent: "",
          cancelContent: "",
          downloadData: "",
          activatedButtonId: 1,
        },
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnClearFilter function",
        error
      );
    }
  };

  /* #region popup summary guest details */
  handleOnHotelSummaryPivotGridCellClick = (e) => {
    try {
      if (
        e.area === "data" &&
        (e.cell.rowType !== "GT" || e.cell.columnType !== "GT") &&
        (e.cell?.rowPath?.length ?? 0) >= 1
      ) {
        const pivotGridDataSource = e.component.getDataSource();
        let rowPathIndex = e.cell.rowPath.find((i) => i);
        this.setState({
          pivotPopupGuestDetailGridDataSource:
            pivotGridDataSource.createDrillDownDataSource(e.cell),
          loadPanelVisible: true,
          popupPivotBookingGuestDetailsTitle:
            e.cell.columnType === "GT" || e.cell.rowType === "GT"
              ? `Booking Details Grand Total`
              : (e.cell?.columnPath?.length ?? 0) === 1
              ? `Total Booking Details Of Year:- ${e.cell.columnPath[0]}`
              : `Booking Details:- ${rowPathIndex} ${e.cell.columnPath[1]
                  .toDateString()
                  .slice(4)}`,
          popupPivotBookingGuestDetailsVisible: true,
        });
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnHotelSummaryPivotGridCellClick function",
        error
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  handleOnHidingPivotBookingPopup = () => {
    try {
      this.setState({
        popupPivotBookingGuestDetailsVisible: false,
        pivotPopupGuestDetailGridDataSource: [],
        popupPivotBookingGuestDetailsTitle: "",
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnHidingPivotBookingPopup function",
        error
      );
    }
  };
  /* #endregion popup summary guest details */

  /* region# download content*/
  handleOnDownloadingContent = async (e, data) => {
    e.preventDefault();
    try {
      let downloadContent = this.state.downloadContent;
      downloadContent.responseContent = data.apiBookResposneContent;
      downloadContent.requestContent = data.apiBookRequestContent;
      downloadContent.cancelContent = data.apiCancelResposneContent;
      downloadContent.showModalPopup = true;
      downloadContent.activatedButtonId = 1;
      downloadContent.downloadContentTitle = "Request Content";
      downloadContent.downloadData = data.apiBookRequestContent ?? "";
      this.setState({
        downloadContent: downloadContent,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnDownloadingContent function",
        error
      );
    }
  };
  handleOnCloseDownloadContentModal = async () => {
    try {
      let downloadContent = this.state.downloadContent;
      downloadContent.responseContent = "";
      downloadContent.requestContent = "";
      downloadContent.cancelContent = "";
      downloadContent.downloadData = "";
      downloadContent.showModalPopup = false;
      downloadContent.activatedButtonId = 0;
      downloadContent.downloadContentTitle = "";
      this.setState({
        downloadContent: downloadContent,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnCloseDownloadContentModal function",
        error
      );
    }
  };
  handleOnSetDownloadActivateButton = async (e, activatedButtonId) => {
    e.preventDefault();
    try {
      let downloadContent = this.state.downloadContent;
      downloadContent.activatedButtonId = activatedButtonId;
      downloadContent.downloadContentTitle = "Request Content";
      downloadContent.downloadData = downloadContent.requestContent ?? "";
      if (activatedButtonId !== 1) {
        downloadContent.downloadContentTitle =
          activatedButtonId === 2 ? "Response Content" : "Cancel Content";
        downloadContent.downloadData =
          (activatedButtonId === 2
            ? downloadContent.responseContent
            : downloadContent.cancelContent) ?? "";
      }
      this.setState({
        downloadContent: downloadContent,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnSetDownloadActivateButton function",
        error
      );
    }
  };
  handleOnDownloadContent = () => {
    const blob = new Blob([this.state.downloadContent.downloadData], {
      type: "text/plain",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "download.txt";
    link.click();
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
    }, 100);
  };
  /* endregion# download content*/
  render() {
    const renderDownloadContent = (data) => {
      return (
        <a
          href="#"
          title="Download Content"
          onClick={(e) => {
            this.handleOnDownloadingContent(e, data.data);
          }}
        >
          {" "}
          <i className="fas fa-download"></i>
        </a>
      );
    };
    /*region pivot cell click able*/
    const onCellPrepared = ({ cell, area, cellElement }) => {
      cell.area = area;
      if (
        ((cell.area === "data" &&
          cell.rowType === "D" &&
          cell.rowType === "T") ||
          (cell?.rowPath?.length ?? 0) >= 1) &&
        cell.value !== undefined
      ) {
        const appearance = getConditionalAppearance(cell);
        Object.assign(cellElement.style, getCssStyles(appearance));
      }
    };
    function getCssStyles({ underline }) {
      return {
        "text-decoration": underline ? "underline" : undefined,
        color: "purple" ? "purple" : undefined,
        cursor: "pointer" ? "pointer" : undefined,
      };
    }
    function getConditionalAppearance(cell) {
      if (
        ((cell.area === "data" &&
          cell.rowType === "D" &&
          cell.rowType === "T") ||
          (cell?.rowPath?.length ?? 0) >= 1) &&
        cell.value !== undefined
      ) {
        return {
          underline: true,
          color: "purple",
          cursor: "pointer",
        };
      }
      return cell;
    }
    /*endregion pivot cell click able*/
    return (
      <>
        <div className="filter-box">
          <div className="card p-2 m-0">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Airport</label>
                  <SelectBox
                    dataSource={this.state.dropdownAirportDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportCode"
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Hotel</label>
                  <SelectBox
                    dataSource={this.state.dropdownHotelDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Hotel"
                    name="hotelId"
                    value={this.state.hotelId}
                    displayExpr="hotelName"
                    valueExpr="hotelId"
                    onSelectionChanged={this.handleHotelChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>From Time (Check-in)</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterFromDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterFromDatetime}
                    value={this.state.filterFromDatetime}
                    onValueChanged={this.handleOnFilterFromDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>To Time (Check-in)</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    min={this.state.filterFromDatetime}
                    max={moment(new Date(this.state.filterFromDatetime)).add(
                      30,
                      "days"
                    )}
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterToDatetime}
                    value={this.state.filterToDatetime}
                    onValueChanged={this.handleOnFilterToDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Employee Number</label>
                  <SelectBox
                    dataSource={this.state.dropdownCrewDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="employeeNumber"
                    value={this.state.employeeNumber}
                    displayExpr="employeeNumber"
                    valueExpr="employeeNumber"
                    onSelectionChanged={this.handleEmployeeChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.handleOnFilterBookingData}
                  >
                    {this.state.isFiltered && (
                      <span title="In-process">
                        <i className="fas fa-spinner fa-spin"></i> In-process
                      </span>
                    )}
                    {!this.state.isFiltered && (
                      <span title="Filter">
                        <i className="fas fa-filter"></i> Filter
                      </span>
                    )}
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="button"
                    className="btn btn-custom-gray"
                    onClick={this.handleOnClearFilter}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-0"
                id="custom-tabs-parent-tab"
                role="parentTabList"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-booking-summary-tab"
                    data-toggle="pill"
                    href="#custom-tabs-booking-summary"
                    role="tab"
                    aria-controls="custom-tabs-booking-summary"
                    aria-selected="true"
                    value={0}
                  >
                    Booking Summary
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-booking-details-tab"
                    data-toggle="pill"
                    href="#custom-tabs-booking-details"
                    role="tab"
                    aria-controls="custom-tabs-booking-details"
                    aria-selected="false"
                    value={1}
                  >
                    Booking Details
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-booking-summary"
                role="tabpanel"
                aria-labelledby="custom-tabs-booking-summary-tab"
              >
                <PivotGrid
                  id="custom-tabs-booking-summary"
                  wordWrapEnabled={false}
                  dataSource={this.state.pivotGridHotelSummaryDataSource}
                  showBorders={true}
                  height="620"
                  allowSorting={true}
                  allowSortingBySummary={true}
                  rowHeaderLayout={
                    this.state.rowHeaderLayout ? "tree" : "standard"
                  }
                  allowFiltering={true}
                  onCellClick={this.handleOnHotelSummaryPivotGridCellClick}
                  onCellPrepared={onCellPrepared}
                >
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="HotelBookingSummary"
                  />
                  <FieldChooser height="500" enabled={true} />
                  <Scrolling mode="virtual" />
                </PivotGrid>
              </div>
              <div
                className="tab-pane fade"
                id="custom-tabs-booking-details"
                role="tabpanel"
                aria-labelledby="custom-tabs-booking-details-tab"
              >
                <div className="card">
                  <div className="card-body">
                    <DataGrid
                      dataSource={this.state.hotelBookingSummaryGridDataSource}
                      selection={{ mode: "single" }}
                      columnsAutoWidth={false}
                      showColumnLines={true}
                      showRowLines={true}
                      showBorders={true}
                      rowAlternationEnabled={false}
                      allowColumnResizing={true}
                    >
                      <Column
                        width={80}
                        dataField="airportCode"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Airport"
                      />
                      <Column
                        width={300}
                        dataField="supplierBookingReferenceNo"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Booking Ref #"
                      />
                      <Column
                        width={170}
                        dataField="hotelName"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Hotel"
                      />
                      <Column
                        width={100}
                        dataField="startDate"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Start Date"
                      />
                      <Column
                        width={120}
                        dataField="duration"
                        alignment={"center"}
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Duration"
                      />
                      <Column
                        width={140}
                        dataField="flightTime"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Flight Time"
                        dataType="datetime"
                        format="MM/dd/yyyy HH:mm:ss"
                      />
                      <Column
                        width={100}
                        dataField="flightNumber"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Flight No"
                      />
                      <Column
                        width={100}
                        dataField="guestCount"
                        alignment={"center"}
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Guest #"
                      />
                      <Column
                        width={130}
                        dataField="crewTypeCategory"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Crew Category"
                      />
                      <Column
                        width={210}
                        dataField="leadPassenger"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Lead Passenger"
                      />
                      <Column
                        width={80}
                        dataField="crewGender"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Gender"
                      />
                      <Column
                        width={100}
                        dataField="bookingStatus"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Status"
                      />
                      <Column
                        width={340}
                        dataField="cancellationReferenceNo"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Cancellation Ref #"
                      />
                      <Column
                        width={140}
                        dataField="createdDate"
                        allowSearch={true}
                        allowFiltering={true}
                        allowSorting={true}
                        caption="Created At"
                        dataType="datetime"
                        format="MM/dd/yyyy HH:mm:ss"
                      />
                      <Column
                        width={"45"}
                        dataField=""
                        allowSearch={true}
                        allowFiltering={false}
                        allowSorting={true}
                        alignment={"center"}
                        cellRender={renderDownloadContent}
                      />
                      <MasterDetail
                        enabled={true}
                        render={({ data }) => {
                          return (
                            <ChildBookingDataComponent
                              bookingGuestData={this.state.hotelBookingLst}
                              bookingRates={this.state.hotelBookingRateLst}
                              bookingReferenceNo={data.bookingReferenceNo}
                            />
                          );
                        }}
                      ></MasterDetail>
                      <SortByGroupSummaryInfo summaryItem="count" />
                      <GroupPanel visible={false} />
                      <HeaderFilter visible={true} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        width={240}
                        placeholder="Search..."
                      />
                      <Grouping autoExpandAll={true} />
                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 50]}
                        showInfo={true}
                        visible={true}
                      />
                      <Export
                        enabled={true}
                        allowExportSelectedData={false}
                        fileName="HotelBooking"
                      />
                    </DataGrid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* summary pivot guest currentBookingData  popup */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.popupPivotBookingGuestDetailsVisible}
          style={{ content: { width: "60%" } }}
          className={"react-modal"}
          onRequestClose={this.handleOnHidingPivotBookingPopup}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              {this.state.popupPivotBookingGuestDetailsTitle}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleOnHidingPivotBookingPopup}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="card-body">
                <DataGrid
                  dataSource={this.state.pivotPopupGuestDetailGridDataSource}
                  selection={{ mode: "single" }}
                  columnAutoWidth={true}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                >
                  <Column
                    dataField="supplierBookingReferenceNo"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Booking Ref #"
                  />
                  <Column
                    dataField="hotelName"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Hotel"
                  />
                  <Column
                    dataField="duration"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Duration"
                  />
                  <Column
                    dataField="flightTime"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Flight Time"
                    dataType="datetime"
                    format="MM/dd/yyyy HH:mm:ss"
                  />
                  <Column
                    dataField="flightNumber"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Flight Number"
                  />
                  <Column
                    dataField="employeeNumber"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Employee Number"
                  />
                  <Column
                    dataField="crewFirstName"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Crew First Name"
                  />
                  <Column
                    dataField="crewLastName"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Crew Last Name"
                  />
                  <Column
                    dataField="crewGender"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Crew Gender"
                  />
                  <Column
                    dataField="airlineCrewType"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Crew Type"
                  />
                  <Column
                    dataField="tripId"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Trip #"
                  />
                  <Column
                    dataField="checkinTime"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Check In Time"
                    dataType="datetime"
                    format="MM/dd/yyyy HH:mm:ss"
                  />
                  <Column
                    dataField="checkoutTime"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Check Out Time"
                    dataType="datetime"
                    format="MM/dd/yyyy HH:mm:ss"
                  />
                  <Column
                    dataField="createdDate"
                    allowSearch={true}
                    allowFiltering={true}
                    allowSorting={true}
                    caption="Created At"
                    dataType="datetime"
                    format="MM/dd/yyyy HH:mm:ss"
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="HotelBookingGuest"
                  />
                  <Paging defaultPageSize={10} />
                </DataGrid>
              </div>
            </div>
          </div>
        </Modal>

        {/* download content modal popup */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.downloadContent.showModalPopup}
          style={{ content: { width: "60%" } }}
          className={"react-modal"}
          onRequestClose={this.handleOnCloseDownloadContentModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              {this.state.downloadContent.downloadContentTitle}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleOnCloseDownloadContentModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div className="form-group">
                  <button
                    onClick={(e) => {
                      this.handleOnSetDownloadActivateButton(e, 1);
                    }}
                    type="button"
                    className={
                      this.state.downloadContent.activatedButtonId === 1
                        ? "btn btn-primary btn-sm"
                        : "btn btn-custom-gray "
                    }
                  >
                    <span title="Request">Request</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    onClick={(e) => {
                      this.handleOnSetDownloadActivateButton(e, 2);
                    }}
                    type="button"
                    className={
                      this.state.downloadContent.activatedButtonId === 2
                        ? "btn btn-primary btn-sm"
                        : "btn btn-custom-gray "
                    }
                  >
                    <span title="Response">Response</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    onClick={(e) => {
                      this.handleOnSetDownloadActivateButton(e, 3);
                    }}
                    type="button"
                    className={
                      this.state.downloadContent.activatedButtonId === 3
                        ? "btn btn-primary btn-sm"
                        : "btn btn-custom-gray "
                    }
                  >
                    <span title="Cancel">Cancel</span>
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
              <div className="col-lg-6 col-md-2 col-sm-2 col-xs-2 text-right">
                <div className="form-group">
                  <button
                    onClick={this.handleOnDownloadContent}
                    type="button"
                    className="btn btn-link btn-sm"
                  >
                    <span title="Download">
                      <i className="fas fa-download"></i> Download & Copy
                    </span>
                  </button>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <textarea
                    style={{ height: "initial" }}
                    id="downloadData"
                    rows={8}
                    className="form-control form-textbox form-control-sm"
                    value={this.state.downloadContent.downloadData}
                    readOnly={true}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#historydiv" }}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </>
    );
  }
}
